<template lang="">
    <p>Reaction time - {{ score }} ms</p>
    <p class="rank"> {{rank}} </p>
</template>
<script>
export default {
    props: ['score'],
    data () {
        return {
            rank: null
        }
    },
    mounted () {
        if (this.score < 250) {
            this.rank = 'You are a monster!!'
        } else if (this.score < 500) {
            this.rank = 'You can do better!'
        } else if (this.score < 1000) {
            this.rank = 'Are you paying attention?'
        } else {
            this.rank = 'Yikes...'
        }
    }
}
</script>
<style>
    .rank {
        font-size: 2rem;
        font-weight: bold;
        color: #0faf87;
    }
</style>